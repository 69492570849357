@import "../settings";

// ---------------------------
// Default repeater logo.
// ---------------------------
.repeater-logo {
  text-align: center;

  @include breakpoint(large) {
    padding-bottom: $global-padding * 10;
  }

  // Parent flex container.
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -$global-margin * 2;

    @include breakpoint(medium) {
      margin-bottom: 0;
    }

    &.repeater-logo__items--3 {
      .repeater-logo__item {
        @include breakpoint( large ) {
          flex: 0 0 calc(33.33% - #{$global-margin});
          max-width: calc(33.33% - #{$global-margin});
        }
      }
    }

    &.repeater-logo__items--4 {
      .repeater-logo__item {
        @include breakpoint( large ) {
          flex: 0 0 calc(25% - #{$global-margin});
          max-width: calc(25% - #{$global-margin});
        }
      }
    }

    &.repeater-logo__items--5 {
      .repeater-logo__item {
        @include breakpoint( large ) {
          flex: 0 0 calc(20% - #{$global-margin});
          max-width: calc(20% - #{$global-margin});
        }
      }
    }

    &.repeater-logo__items--6 {
      .repeater-logo__item {
        @include breakpoint( large ) {
          flex: 0 0 calc(16.666666% - #{$global-margin});
          max-width: calc(16.666666% - #{$global-margin});
        }
      }
    }
  }

  &__item {
    align-items: center;
    display: flex;
    flex: 0 1 calc(50% - #{$global-margin});
    justify-content: center;
    padding: 0 $global-margin / 2 $global-margin * 2;

    @include breakpoint(medium) {
      flex: 0 0 16.666666%;
      max-width: 16.666666%;
      padding: $global-padding;
    }

    a {
      transition: all 0.3s ease-in-out;

      &:hover,
      &:focus {
        opacity: .5;
        scale: 1.1;
      }

    }
  }

  // Center the view all button and push it down from the cards.
  &__view-all {
    margin-top: $global-margin * 2;
    text-align: center;
  }
}


